{
  "transactions": {
    "tittle": "Transacciones",
    "dateRangeFilter": "Fechas",
    "typeFilter": "Tipo",
    "add": "Agregar Transaccion",
    "edit": "Editar Transaccion",
    "type": {
      "all": "Todos",
      "revenue": "Ganado",
      "spend": "Gastado",
      "movement": "Movimiento",
      "undefined": "No Definido"
    },
    "table": {
      "ammount": "MONTO",
      "transactionDate": "FECHA TRANSACCION",
      "type": "TIPO",
      "source": "FUENTE",
      "active": "ACTIVA"
    },
    "moreInfo": "Mas Informacion"
  },
  "budgets": {
    "tittle": "Presupuestos",
    "typeFilter": "Tipo",
    "budgetType": "Tipo Presupuesto",
    "add": "Agregar Presipuesto",
    "type": {
      "all": "Todos",
      "Daily": "Diario",
      "Monthly": "Mensual",
      "Yearly": "Anual"
    },
    "table": {
      "ammount": "MONTO",
      "type": "TIPO",
      "TransactionType": "TIPO TRANSACCION",
      "source": "TIPO FUENTE",
      "active": "ACTIVO"
    },
    "history-table": {
      "targetAmmount": " MONTO PRESUPUESTO",
      "currentAmmount": "MONTO ACTUAL",
      "type": "TIPO",
      "transactionType": "TIPO TRANSACCION",
      "source": "FUENTE",
      "startDate": "FECHA INICIO",
      "endDate": "FECHA FIN",
      "active": "ACTIVO"
    },
    "moreInfo": "Mas Informacion"
  },
  "loans": {
    "tittle": "Prestado",
    "add": "Agregar ",
    "frecuency": {
      "all": "Todos",
      "Daily": "Diario",
      "Monthly": "Mensual",
      "Yearly": "Anual"
    },
    "table": {
      "lend": "Quien",
      "borrow": "A quien",
      "ammountBorrowed": "Cuanto",
      "paymentFrecuencyName": "Frecuencia",
      "percentage": "Porcentaje",
      "paid": "Pagó"
    },
    "history-table": {
      "paymentDate": "Fecha Pago",
      "transaction": "Transaccion realizada",
      "transactionTypeName": "Tipo Transaccion",
      "ammountToPay": "A Pagar",
      "ammoundPaid": "Pagado",
      "generated": "Generado"
    }
  },
  "sources": {
    "tittle": "Fuentes",
    "table": {
      "name": "NOMBRE",
      "description": "DESCRIPCION",
      "active": "ACTIVA"
    },
    "add": "Agregar Fuente",
    "edit": "Editar Fuente"
  },
  "account": {
    "status": {
      "Positive": "Positivo",
      "Medium": "Balanceado",
      "Negative": "Negativo"
    }
  },
  "auth": {
    "agree": "Acepto",
    "createAccount": "Crear cuenta",
    "createNewAccount": "Crear cuenta nueva",
    "email": "Email",
    "username": "Nombre usuario",
    "phone": "Telefono",
    "login": "Iniciar sesión",
    "repeat_password": "Repetir Contraseña",
    "password": "Contraseña",
    "recover_password": "Recuperar contraseña",
    "forgot_password": "Olvidaste tu contraseña?",
    "sign_up": "Registrar",
    "termsOfUse": "Términos de uso",
    "reset_password": "Restablecer contraseña",
    "password_help": "La contraseña debe contener +8 caracteres, letras, numeros y caracteres especiales."
  },
  "404": {
    "title": "Esta página se ha ido a pescar",
    "text": "Si crees que esto no es correcto, por favor envíanos un mensaje a ",
    "back_button": "Volver al tablero"
  },
  "typography": {
    "primary": "Estilos de texto primarios",
    "secondary": "Estilos de texto secundarios"
  },
  "dashboard": {
    "versions": "Versiones",
    "setupRemoteConnections": "Configurar conexiones remotas",
    "currentVisitors": "Visitantes actuales",
    "navigationLayout": "Diseño de navegación",
    "topBarButton": "Barra superior",
    "sideBarButton": "Barra lateral"
  },
  "language": {
    "english": "Inglés",
    "spanish": "Español"
  },
  "menu": {
    "home": "Principal",
    "dashboard": "Tablero",
    "transactions": "Transacciones",
    "sources": "Fuentes",
    "budgets": "Presupuestos",
    "loans": "Prestado"
  },
  "messages": {
    "all": "Ver todos los mensajes",
    "new": "Nuevos mensajes de {name}",
    "mark_as_read": "Marcar como leído"
  },
  "navbar": {
    "messageUs": "Preguntas sobre desarrollo web:",
    "repository": "Repositorio GitHub"
  },
  "notifications": {
    "all": "Ver todas las notificaciones",
    "less": "Ver menos notificaciones",
    "mark_as_read": "Marcar como leída",
    "sentMessage": "{name} te envió un mensaje"
  },
  "helpAndSupport": "Ayuda y Soporte",
  "aboutVuesticAdmin": "Acerca de Vuestic Admin",
  "search": {
    "placeholder": "Buscar..."
  },
  "buttonSelect": {
    "dark": "Oscuro",
    "light": "Claro"
  },
  "user": {
    "settings": "Configuracion",
    "logout": "Cerrar Sesión",
    "account": "account"
  }
}
